<template>
  <div class="wrap">
    <myHead></myHead>
    <div class="wrap_body" v-loading="is_loading">
      <div class="center">
        <div class="home_main">
          <div class="search_wrap">
            <div class="search_box">
              <div class="type"> 宝贝</div>
              <input class="in" placeholder="输入关键字搜索商品" v-model="searchParams.name" @keyup.enter="refresh()"/>
              <i class="iconfont icon-xiangji" @click.stop="gotoSearch()"></i>
            </div>
            <button class="search_btn" @click.stop="refresh()">搜索</button>
          </div>
          <div class="main_box" @mouseover.stop="bindEventStop()" @mouseout.stop="bindEventStop()">
            <div class="left_type">

              <div class="head_box">
                <i class="el-icon-s-shop"></i>
                <p class="txt">{{ supplierInfo.nickname }}</p>
                <p class="collect" v-if="supplierInfo.collect==0" @click="collect()"><img src="@/assets/images/collect1.png" alt=""> 收藏档口</p>
                <p class="collect" v-else style="color: red"  @click="collect()"><img src="@/assets/images/collect2.png" alt=""> 已收藏</p>
              </div>
              <div class="type_show">
                <p class="title"><i class="iconfont icon-fenlei2"></i>全部品类</p>
                <!-- 分类筛选 -->
                <div class="type_line" v-if="market_list.cate_list&&market_list.cate_list.length">
                  <div class="type-head">
                    <div class="open-or-close" @click="market_list.isOpen=!market_list.isOpen">
                      {{ market_list.isOpen ? '-' : '+' }}
                    </div>
                    <div class="con" @click=" market_list.isOpen=true">
                      <span>{{ market_list.name }}</span>
                    </div>
                  </div>
                  <div class="sub-cate-wrap" v-if="market_list.isOpen">
                    <template v-for="child in market_list.cate_list">
                        <div class="sub-cate"
                             :class="(searchParams.market_id==child.market_id)?'active':''"
                             @click.stop="bindMarketTap(child.market_id)">{{ child.market_name }}
                        </div>
                    </template>
                  </div>
                </div>
                <!-- 分类筛选 end -->
                <!-- 分类筛选 -->
                <div class="type_line" v-for="(cate,index) in cateGroupList" :key="`care_${index}`">
                  <div class="type-head">
                    <div class="open-or-close" @click="cate.isOpen=!cate.isOpen">
                      {{ cate.isOpen ? '-' : '+' }}
                    </div>
                    <div class="con" @click=" cate.isOpen=true">
                      <span>{{ cate.name }}</span>
                    </div>
                  </div>
                  <div class="sub-cate-wrap" v-if="cate.isOpen">
                    <template v-for="child in cate.cate_list">
                      <template v-for="(subCate,subIndex) in child.children">
                        <div class="sub-cate" :key="subIndex"
                             :class="(searchParams.cate_id[0]==child.id&&searchParams.cate_id[1]==subCate.id)?'active':''"
                             @click.stop="bindCateTap(child.id,subCate.id)">{{ subCate.name }}
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
                <!-- 分类筛选 end -->
                <div class="type_line" v-if="style_list.cate_list&&style_list.cate_list.length">
                  <div class="type-head">
                    <div class="open-or-close" @click="style_list.isOpen=!style_list.isOpen">
                      {{ style_list.isOpen ? '-' : '+' }}
                    </div>
                    <div class="con" @click=" style_list.isOpen=true">
                      <span>{{ style_list.name }}</span>
                    </div>
                  </div>
                  <div class="sub-cate-wrap" v-if="style_list.isOpen">
                    <template v-for="child in style_list.cate_list">
                      <div class="sub-cate"
                           :class="(searchParams.style_id==child.style_id)?'active':''"
                           @click.stop="bindStyleTap(child.style_id)">{{ child.style_name }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="right_con">
              <div class="product_section">
<!--                <div class="screen_wrap">
                  &lt;!&ndash;                  <p class="title">为你推荐</p>&ndash;&gt;
                  <p class="tab" :class="searchParams.time_sort==1?'active':''" @click="searchTap('time_sort',1)">
                    上新时间</p>
                  <p class="tab" :class="searchParams.sales_sort==1?'active':''" @click="searchTap('sales_sort',1)">
                    全网销量</p>
                  <p class="tab" :class="searchParams.sales_sort==2?'active':''" @click="searchTap('sales_sort',2)">
                    平台销量</p>
                  <p class="tab" :class="searchParams.free_express==1?'active':''" @click="searchTap('free_express',1)">
                    包邮</p>

                  <p class="tab" :class="searchParams.in_stock==1?'active':''" @click="searchTap('in_stock',1)">
                    现货48小时</p>
                  <p class="tab" :class="searchParams.returnable==1?'active':''" @click="searchTap('returnable',1)">
                    可退货</p>
                  <p class="tab" :class="searchParams.pre_sale==1?'active':''" @click="searchTap('pre_sale',1)">预售</p>
                  <p class="tab" :class="searchParams.new_product==1?'active':''" @click="searchTap('new_product',1)">
                    新品</p>
                  <el-dropdown class="tab" :class="searchParams.price_sort>0?'active':''" trigger="click"
                               @command="priceCommand">
                    <span class="el-dropdown-link">
                      {{
                        searchParams.price_sort == 1 ? '价格从高到低' : searchParams.price_sort == 2 ? '价格从低到高' : '价格'
                      }}
                      <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">默认</el-dropdown-item>
                      <el-dropdown-item command="1">价格从高到低</el-dropdown-item>
                      <el-dropdown-item command="2">价格从低到高</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  &lt;!&ndash;                  <el-dropdown class="tab" :class="searchParams.sales_sort>0?'active':''" trigger="click"
                                                 @command="salesCommand">
                                      <span class="el-dropdown-link">
                                        {{
                                          searchParams.sales_sort == 1 ? '销量从高到低' : searchParams.sales_sort == 2 ? '销量从低到高' : '销量'
                                        }}<i
                                          class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                                      </span>
                                      <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="0">默认</el-dropdown-item>
                                        <el-dropdown-item command="1">销量从高到低</el-dropdown-item>
                                        <el-dropdown-item command="2">销量从低到高</el-dropdown-item>
                                      </el-dropdown-menu>
                                    </el-dropdown>&ndash;&gt;
                  <el-dropdown class="tab" :class="searchParams.popularity_sort>0?'active':''" trigger="click"
                               @command="popularityCommand">
                    <span class="el-dropdown-link">
                      {{
                        searchParams.popularity_sort == 1 ? '人气从高到低' : searchParams.popularity_sort == 2 ? '人气从低到高' : '人气'
                      }}<i
                        class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">默认</el-dropdown-item>
                      <el-dropdown-item command="1">人气从高到低</el-dropdown-item>
                      <el-dropdown-item command="2">人气从低到高</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <p class="in_box">￥<input class="in" v-model="searchParams.min_price"/></p>
                  <p class="in_line">-</p>
                  <p class="in_box">￥<input class="in" v-model="searchParams.max_price"/></p>
                  <p class="in_btn" @click="refresh()">确定</p>
                  <p class="in_btn" @click="reset()">清空</p>
                  &lt;!&ndash;                  <p class="in_btn" @click="selectAll()">全选</p>
                                    <p class="in_btn" @click="allPuhuo()">一键铺货</p>&ndash;&gt;
                </div>-->
                <div class="product_section">
                  <div class="screen_wrap">
                    <!-- <p class="title">为你推荐</p> -->
                    <p class="tab" :class="searchParams.sort_type==4?'active':''" @click="bindSortTypeTap(4)">综合排序</p>
                    <p class="tab" :class="searchParams.sort_type==1?'active':''" @click="bindSortTypeTap(1)">新品</p>
                    <p class="tab" :class="searchParams.sort_type==2?'active':''" @click="bindSortTypeTap(2)">销量</p>
                    <p class="tab" :class="searchParams.sort_type==3?'active':''" @click="bindSortTypeTap(3)">人气</p>

                    <el-popover
                        placement="bottom"
                        popper-class="ageing-box"
                        trigger="click"
                        v-model="showAgeing">
                      <div class="ageing-list">
                        <div class="ageing-item" :class="searchParams.ageing_type==item.type?'active':''" v-for="(item,index) in ageingList" :key="index" @click="bindAgeingTap(item)">{{item.text}}</div>
                      </div>
                      <p class="tab" slot="reference">
                        <template v-if="ageing_text==''">预售<i class="el-icon-arrow-down el-icon--right"></i></template>
                        <template v-else>
                          {{ageing_text}}<i @click.stop="bindCleanAgeing()" class="el-icon-close"></i>
                        </template>
                      </p>
                    </el-popover>
                    <el-popover
                        placement="bottom"
                        popper-class="ageing-box"
                        trigger="click"
                        v-model="showPublic">
                      <div class="ageing-list">
                        <div v-for="(item,index) in publicList" :key="index">
                          <div class="ageing-item" :class="searchParams.public_type==item.type?'active':''" @click="bindPublicTap(item)" v-if="item.type!=999">{{item.text}}</div>
                          <div class="ageing-item" v-if="item.type==999&&!showPublicRange" @click="bindPublicTap(item)">{{item.text}}</div>
                        </div>

                        <div class="ageing-item" v-if="showPublicRange">
                          <el-date-picker
                              size="mini"
                              v-model="timeRange"
                              type="daterange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              @change="bindPublicRangeChange"
                          >
                          </el-date-picker>
                        </div>
                      </div>
                      <p class="tab" slot="reference">
                        <template v-if="public_text==''">上新<i class="el-icon-arrow-down el-icon--right"></i></template>
                        <template v-else>
                          {{public_text}}<i @click.stop="bindCleanPublic()" class="el-icon-close"></i>
                        </template>
                      </p>
                    </el-popover>

                    <!-- <p class="tab" :class="searchParams.free_express==1?'active':''" @click="searchTap('free_express',1)">包邮</p> -->
                    <el-dropdown class="tab" :class="searchParams.price_sort>0?'active':''" trigger="click"
                                 @command="priceCommand">
							<span class="el-dropdown-link">
								{{searchParams.price_sort==1?'价格从高到低':searchParams.price_sort==2?'价格从低到高':'价格'}}<i
                  class="el-icon-arrow-down el-icon--right"></i>
							</span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="0">默认</el-dropdown-item>
                        <el-dropdown-item command="1">价格从高到低</el-dropdown-item>
                        <el-dropdown-item command="2">价格从低到高</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <p class="totle-text">共找到{{page_data.total}}件商品</p>
                  </div>

                  <div class="ext-screen" style="justify-content: flex-start">
                    <el-checkbox-group class="check-list" v-model="checkExtList" @change="bindCheckExtChange()">
						        	<el-checkbox :label="6">48小时专区</el-checkbox>
                <el-checkbox :label="7">优选专区</el-checkbox>
                      <el-checkbox :label="1">可退货</el-checkbox>
                      <el-checkbox :label="2">可换货</el-checkbox>
                      <el-checkbox :label="3">合并同款</el-checkbox>
                      <el-checkbox :label="4">隐藏已铺货</el-checkbox>
                      <!-- <el-checkbox :label="6" v-if="showSpecialArea">48小时专区</el-checkbox> -->
                      <el-checkbox :label="6" >48小时专区</el-checkbox>
<!--                      <el-checkbox :label="5">关联收藏</el-checkbox>-->
                    </el-checkbox-group>
                    <div v-if="checkExtList==4" style="display: flex;align-items: center">
                      <div style="margin: -1px 10px 0 2px;font-size: 12px;color: #666">店铺名</div>
                      <el-select style="width: 180px" @clear="refresh()" @change="refresh()" v-model="searchParams.shop_id" placeholder="请选择" clearable filterable size="mini">
                        <el-option v-for="item in shopLists" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                        </el-option>
                      </el-select>
                    </div>
                    <p class="in_box">￥<input class="in" v-model="searchParams.min_price" /></p>
                    <p class="in_line">-</p>
                    <p class="in_box">￥<input class="in" v-model="searchParams.max_price" /></p>
                    <p class="in_btn" @click="refresh()">确定</p>
                    <!-- <p class="in_btn" @click="reset()">清空</p> -->
                    <p class="in_btn" @click="selectAll()">全选</p>
                    <p class="in_btn" @click="allPuhuo()">一键铺货</p>
<!--                    <div style="margin: -1px 10px 0 50px;font-size: 12px;color: #666">店铺名</div>
                    <el-select class="w290" @clear="refresh()" @change="refresh()" v-model="searchParams.shop_id" placeholder="请选择" clearable filterable size="mini">
                      <el-option v-for="item in shopLists" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                      </el-option>
                    </el-select>

                    <div class="ageing-list">
                      <div class="ageing-item" :class="searchParams.ageing_type==ageing.type?'active':''" v-for="(ageing,index) in ageingList" :key="index" @click="bindAgeingTap(ageing.type)">{{ageing.text}}</div>
                    </div>-->
                  </div>
                  <productList style="padding: 0px 0 20px 20px;" :listData="list" :loading="product_loading" ref="products"></productList>

                  <div class="turn_page">
                    <Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit"
                                @pagination="getProduct" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20,50,100,200,500,1000]" />
                  </div>

                </div>

<!--                <productList :listData="list" list-class="four" :loading="product_loading" ref="products"></productList>-->

<!--                <div class="turn_page">-->
<!--                  <Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit"-->
<!--                              @pagination="getProduct" layout="total, sizes, prev, pager, next, jumper"-->
<!--                              :page-sizes="[10,50,100]"/>-->
<!--                </div>-->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <shopList ref="child"></shopList>

    <myFoot :showBeian="true"></myFoot>
  </div>
</template>
<script>

import myHead from "@/components/myHead/myhead.vue";
import {mapActions, mapMutations} from "vuex";
import productList from "@/components/productList/productlist.vue";
import Pagination from '@/components/pagination/pagination';
import myFoot from "@/components/myFoot/myfoot.vue";
import shopList from "@/components/shopList/shopList.vue";

export default {
  components: {shopList, myFoot, productList, Pagination, myHead},
  data() {
    return {
      supplierId: "",
      supplierInfo: {},
      headImg: "",
      is_loading: false,
      product_loading: false,
      searchParams: {
        name_type: 0,
        cate_id: [0, 0],
        shop_id: '',
      },
      list: [],
      page_data: {
        limit: 20,
        page: 1,
        total: 0,
      },
      currGroupName: '',
      currGroupId: 0,
      cateGroupList: [],
      market_list: {},
      style_list: {},
      checkExtList: [],
      shopLists:[],
      ageingList: [],
      collect_loading: false,

      publicList: [],
      showPublic: false,
      showAgeing: false,
      ageing_text: '',
      public_text: '',
      timeRange: '',
      showPublicRange: false,
      showSpecialArea: false
    }
  },
  mounted() {
    this.supplierId = this.$route.query.id;
    this.getCateList()
    this.getProduct(1)

  },
  created() {
    // 从localStorage获取用户信息并判断是否显示48小时专区
    const userInfo = localStorage.getItem('userinfo')
    if (userInfo) {
      try {
        const user = JSON.parse(userInfo)
        this.showSpecialArea = user.auto_shipments === 1
      } catch (e) {
        console.error('Parse userinfo error:', e)
        this.showSpecialArea = false
      }
    }
  },
  methods: {
    ...mapActions({
      getSupplierCate: "index/getSupplierCate",
      getMallProductList: "index/getMallProductList",
      getMallCateData:"index/getMallCateData",
      doCollectSup: "index/doCollectSup",
      doSubmitPH:"cart/doSubmitPH",
    }),
    ...mapMutations({
      OUT_LOGIN:"login/OUT_LOGIN"
    }),
    bindMarketTap(val){
      this.searchParams.market_id = val
      this.refresh()
    },
    bindStyleTap(val){
      this.searchParams.style_id = val
      this.refresh()
    },
    // bindAgeingTap(type){
    //   let ageing_type = this.searchParams.ageing_type
    //   this.searchParams.ageing_type = ageing_type == type ? 0 : type
    //   this.refresh()
    // },
    bindCheckExtChange(){
      let arr = this.checkExtList
      this.searchParams.is_returnable = arr.includes(1) ? 1 : 0
      this.searchParams.is_changeable = arr.includes(2) ? 1 : 0
      this.searchParams.drop_same = arr.includes(3) ? 1 : 0
      this.searchParams.drop_puhuo = arr.includes(4) ? 1 : 0
		  this.searchParams.is_deliver = arr.includes(6) ? 1 : 0; 
      this.searchParams.is_preferred = arr.includes(7) ? 1 : 0;

      this.refresh()
    },
    phSubmit(val,strategy){//立即铺货
      let param = {shop_id:val,type:'1',product_id:this.$refs.products.select_product_ids,strategy:strategy}
      this.doSubmitPH({data:param,success:(res)=>{
          if(res.code===200){
            this.$message({message: res.msg,type: 'success',});
            setTimeout(()=>{
              this.$refs.child.closeShopList()
            },300)
          } else {
            this.$message({message: res.msg,type: 'error',});
          }
        }})
    },
    bindAgeingTap(item){
      let ageing_type = this.searchParams.ageing_type
      this.searchParams.ageing_type = ageing_type == item.type ? 0 : item.type
      this.showAgeing = false
      this.ageing_text = item.text
      this.refresh()
    },
    bindCleanAgeing(){
      this.searchParams.ageing_type = 0
      this.showAgeing = false
      this.ageing_text = ''
      this.refresh()
    },
    bindPublicTap(item){
      if(item.type == 999){
        this.showPublicRange = true
        return
      }
      this.showPublicRange = false
      let public_type = this.searchParams.public_type
      this.searchParams.public_type = public_type == item.type ? 0 : item.type
      this.showPublic = false
      this.public_text = item.text
      this.refresh()
    },
    bindPublicRangeChange(e){
      this.public_text = e[0] + '至' + e[1]
      this.showPublic = false
      this.searchParams.time_range = e
      this.searchParams.public_type = 999
      this.refresh()
    },
    bindCleanPublic(){
      this.searchParams.public_type = 0
      this.showPublic = false
      this.public_text = ''
      this.refresh()
    },

    //获取商品分类
    getCateList() {
      this.is_loading = true
      var params = {supplier_user_id: this.supplierId}
      this.getSupplierCate({
        data: params, success: (res) => {
          if (res.code == 200) {
            if( res.data.cate.lists.length > 0){
              this.cateGroupList = res.data.cate.lists.map(item => {
                item.isOpen = true
                return item
              })
            }

            this.market_list = {
              cate_list:res.data.cate.market_list,
              isOpen:true,
              name:'市场'
            }

            this.style_list = {
              cate_list:res.data.cate.style_list,
              isOpen:true,
              name:'风格'
            }
            this.ageingList = res.data.cate.ageing_list
            this.publicList = res.data.cate.public_list
            this.supplierInfo = res.data.supplier
          }
          if(res.code==201){
            this.OUT_LOGIN()
            this.$message.error(res.msg)
          }

          this.is_loading = false
        }
      })
/*      this.getMallCateData({data:{},success:(res) => {
          if(res.code == 200 ){

            if( res.data.lists.length > 0){
              this.cateGroupList = res.data.lists.map(item => {
                item.isOpen = true
                return item
              })
            }

            this.market_list = {
              cate_list:res.data.market_list,
              isOpen:true,
              name:'市场'
            }

            this.style_list = {
              cate_list:res.data.style_list,
              isOpen:true,
              name:'风格'
            }
            this.ageingList = res.data.ageing_list
            this.publicList = res.data.public_list


            // this.marketList = res.data.market_list
            // if( res.data.market_list.length > 0){
            //   this.marketList = res.data.market_list.map(item => {
            //     item.isOpen = true
            //     return item
            //   })
            // }
            // this.styleList = res.data.style_list
            // this.siteList = res.data.site_list
          }
        }})*/
    },
    gotoSearch() {
      let param = this.searchParams
      this.$router.push({name: 'search', query: param})
    },
    //刷新列表
    refresh() {
      this.page_data.page = 1
      this.getProduct()
    },
    //滚动加载
    onScroll() {
      // 滚动条到最顶端的距离
      let scrolled = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      if (scrolled + clientHeight + 400 >= scrollHeight) {
        this.loadMore()
      }
    },
    //加载商品
    loadMore() {
      if (this.page_data.total <= this.list.length) {
        return false
      } else {
        this.getProduct()
      }
    },

    //获取商品列表
    getProduct() {
      if (this.product_loading) return
      this.product_loading = true
      this.list = []
      document.documentElement.scrollTop = 0
      let searchParams = this.searchParams
      let param = {
        supplier_user_id: this.supplierId,
        limit: this.page_data.limit,
        page: this.page_data.page,
        state: this.state
      }
      Object.assign(param, searchParams)
      this.getMallProductList({
        data: param, success: (res) => {
          if (res.code === 200) {
            this.list = res.data.data
            this.page_data.total = res.data.total
            if(param.page == 1){
              this.shopLists = res.data.filter_data ? res.data.filter_data.shop_list : []
            }
          } else {
            this.$message({message: res.msg, type: 'error',});
          }
          this.product_loading = false
          // this.page_data.page ++
        }
      })
    },
    bindShichangTap(val) {
      this.$set(this.searchParams, "shichang_name", val)
      this.refresh()
    },
    bindCateTap(first_cid, second_cid) {
      let searchParams = this.searchParams
      searchParams.cate_id = [first_cid, second_cid]
      this.searchParams = searchParams
      this.bindSubCateOut()
      this.refresh()
    },
    bindSubCateOut() {
      this.subCateList = []
      this.currGroupName = ''
      this.currGroupId = 0
    },
    selectAll() {
      this.$refs.products.checkAll()
    },
    allPuhuo() {
      let product_ids = this.$refs.products.select_product_ids
      if (product_ids.length == 0) {
        this.$message({
          message: '请先勾选铺货商品',
          type: 'warning',
        });
        return
      }
      this.$refs.child.showShopList()

      // this.doAddAllProduct({data:{product_ids:product_ids},success:(res) => {
      // 	if(res.code == 200){
      // 		this.$message({message: res.msg,type: 'success',});
      // 	}else{
      // 		this.$message({message: res.msg,type: 'error',});
      // 	}
      // }})

    },
    searchTap(name, val) {
      if (!this.searchParams[name] || this.searchParams[name] != val) {
        this.searchParams[name] = val
      } else {
        this.searchParams[name] = 0
      }
      this.refresh()
    },

    priceCommand(val) {
      this.searchParams.price_sort = val
      this.refresh()
    },
    salesCommand(val) {
      this.searchParams.sales_sort = val
      this.refresh()
    },
    popularityCommand(val) {
      this.searchParams.popularity_sort = val
      this.refresh()
    },
    //清空筛选
    reset() {
      this.searchParams = {
        cate_id: [0, 0]
      }
      this.searchParams.shop_id = ''
      this.refresh()
    },
    bindEventStop() {
      return false;
    },

    bindSortTypeTap(val){
      let sort_type = this.searchParams.sort_type
      this.searchParams.sort_type = sort_type==val?0:val
      this.refresh()
    },
    //收藏档口
    collect() {
      if (this.collect_loading) return
      this.collect_loading = true
      let param = {
        supplier_user_id: this.supplierId,
      }
      this.doCollectSup({
        data: param, success: (res) => {
          if (res.code === 200) {
            this.$message({message: res.msg, type: 'success',});
            this.supplierInfo.collect = res.data
          } else {
            this.$message({message: res.msg, type: 'error',});
          }
          setTimeout(()=>{
            this.collect_loading = false
          },1000)
        }
      })
    },
  }
}
</script>


<style scoped>
.home_main {
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 40px;
}

.search_wrap {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.search_wrap .search_box {
  width: 900px;
  height: 50px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 4px 0vw 0vw 4px;
  border: solid 2px #fa5757;
  display: flex;
  align-items: center;
}

.search_wrap .search_box .type {
  width: 84px;
  color: #333333;
  cursor: pointer;
  text-align: center;
  line-height: 26px;
  border-right: 1px solid #ddd;
}

.search_wrap .search_box .in {
  flex: 1;
  width: 0;
  margin: 0 15px;
  color: #333333;
  font-size: 16px;
}

.search_wrap .search_box .icon-xiangji {
  margin-right: 14px;
  font-size: 24px;
  color: #999;
  cursor: pointer;
}

.search_wrap .search_btn {
  width: 132px;
  height: 50px;
  background-image: linear-gradient(180deg, #fa5757 0%, #fb6d6d 100%), linear-gradient(#ffffff, #ffffff);
  background-blend-mode: normal, normal;
  border-radius: 0vw 4px 4px 0vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}

.main_box {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.main_box .left_type {
  width: 220px;
  //height: 384px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-bottom: 12px;
  //padding-top: 24px;
  position: relative;
}

.left_type .head_box {
  width: 100%;
  height: 100px;
  background: #faedee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.left_type .head_box .el-icon-s-shop {
  font-size: 36px;
  color: gray;
}

.left_type .head_box .txt {
  line-height: 1;
  font-weight: bold;
  margin-top: 10px;
  font-size: 14px;
}

.left_type .type_show {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.left_type .type_show .title {
  display: flex;
  align-items: center;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0 20px;
}

.left_type .type_show .title .icon-fenlei2 {
  font-size: 16px;
  margin-right: 8px;
}

.left_type .type_show .type_line {
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 14px;
  flex-direction: column;
}

.left_type .type_show::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.left_type .type_show::-webkit-scrollbar-track {
  background: #efefef;
}

.left_type .type_show::-webkit-scrollbar-thumb {
  background: #bfbfbf;
}

.left_type .type_show::-webkit-scrollbar-corner {
  background: #333;
}

.left_type .type-head {
  display: flex;
  align-items: center;
  width: 100%;
}

.left_type .type-head .open-or-close {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  line-height: 15px;
  border: 1px solid #333333;
  box-sizing: border-box;
  cursor: pointer;
}

.left_type .type-head .icon-wrap {
  display: inline-flex;
  height: 32px;
  width: 20px;
  align-items: center;
  justify-content: center;
}

.left_type .type-head .icon-wrap .cate-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.left_type .type_show .type_line .iconfont {
  font-size: 18px;
  display: none;
}

.left_type .type_show .type_line .con {
  flex: 1;
  width: 0;
  margin-left: 10px;
  font-weight: bold;
  color: #333333;
}

.left_type .type_show .type_line .con > span:hover {
  //color: #fa5757;
  //text-decoration: underline;
  cursor: pointer;
}

.left_type .type_show .type_line .con > em {
  font-weight: bold;
  padding: 0 6px;
}

.left_type .type_show .type_line .con > em:last-child {
  display: none;
}


.left_type .type_show .type_line.hover .con > span {
  color: #fa5757;
  cursor: pointer;
}

.left_type .type_show .type_line .sub-cate-wrap {
  //display: -webkit-box;
  //-webkit-line-clamp: 3;
  //-webkit-box-orient: vertical;
  //overflow: hidden;
  //text-overflow: unset;
  margin-bottom: 10px;
  margin-left: 26px;
}

.left_type .type_show .type_line .sub-cate-wrap .sub-cate {
  //line-height: 32px;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  color: #666666;
}

.left_type .type_show .type_line .sub-cate-wrap .sub-cate.active {
  color: #fa5757;
  text-decoration: underline;
}


.left_type .type_show .type_line .sub-cate-wrap .sub-cate:hover {
  color: #fa5757;
}

.right_con {
  flex: 1;
  margin-left: 15px;
}

.product_section {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background: #fff;
  //margin-top: 20px;
  //padding: 0 20px 20px;
}

.product_section .screen_wrap {
  width: 100%;
  height: 47px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
}

.screen_wrap .title {
  font-weight: bold;
  margin-right: 40px;
}

.screen_wrap .tab {
  font-size: 14px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.screen_wrap .tab:hover {
  color: var(--red);
  text-decoration: underline;
}

.screen_wrap .tab.active {
  color: #fa5757;
}

 .in_box {
  width: 54px;
  height: 24px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 0 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999;
}

 .in_box .in {
  margin-left: 6px;
}

 .in_line {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999999;
}

 .in_btn {
  margin-left: 12px;
  width: auto;
  padding: 0 10px;
  height: 26px;
  background: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: #666666;
}

.el_input {
  margin: 14px auto 0 !important;
  width: 100%;
}


.product_section {
  width: 100%;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  //margin-top: 20px;
  padding-top: 10px
}

.product_section .screen_wrap {
  position: relative;
  width: calc(100% - 40px);
  height: 46px;
  border-bottom: 1px solid #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 20px;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.screen_wrap .title {
  font-weight: 700;
  margin-right: 60px
}

.screen_wrap .tab {
  font-size: 14px;
  margin-right: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer
}

.screen_wrap .tab:hover {
  color: var(--red);
  text-decoration: underline
}

.screen_wrap .tab.active {
  color: #fa5757
}

.screen_wrap .in_box {
  width: 54px;
  height: 24px;
  border: 1px solid #ccc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #999
}

.screen_wrap .in_box .in {
  margin-left: 6px
}

.screen_wrap .in_line {
  width: 20px;
  color: #999
}

.screen_wrap .in_btn,.screen_wrap .in_line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.screen_wrap .in_btn {
  margin-left: 12px;
  width: auto;
  padding: 0 10px;
  height: 26px;
  background: #ddd;
  cursor: pointer;
  font-size: 12px;
  color: #666
}

.ext-screen {
  position: relative;
  width: calc(100% - 40px);
  height: 46px;
  height: 42px;
  background-color: #fff;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.ext-screen .ageing-list,.ext-screen .check-list,.ext-screen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.ext-screen .ageing-list .ageing-item {
  font-size: 12px;
  color: #333;
  margin-left: 25px;
  cursor: pointer
}

.ext-screen .ageing-list .ageing-item.active {
  color: #fa5757
}
/deep/.ext-screen .check-list .el-checkbox__inner {
  width: 16px;
  height: 16px
}
/deep/.ext-screen .check-list .el-checkbox {
 margin-right: 10px;
}

/deep/.ext-screen .check-list .el-checkbox__label {
  font-size: 12px!important
}

.collect{
  margin-top: 10px;
  display: flex;align-items: center;
  cursor: pointer;
  font-size: 13px;
}
.collect img{
  width: 20px;
  margin-right: 5px;
}
.totle-text{
  position: absolute;
  right: 20px;
  font-size: 14px;
  color: #666;
}

.ageing-list{
  position: absolute;
  width: 1160px;
  left: 0;
  top: 0;
  height: 42px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0,0,0.1 );
  z-index: 10;
}
.ageing-list,.ext-screen .check-list,.ext-screen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ageing-list .ageing-item {
  font-size: 12px;
  color: #333;
  margin-left: 25px;
  cursor: pointer
}

.ageing-list .ageing-item.active {
  color: #fa5757
}
</style>